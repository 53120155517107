import React from 'react';

import Layout from '../components/layout/Layout';
import { ContentServices } from '../components/ContentServices';
import { services } from '../data/servizi-aggiuntivi/services';
import Seo from '../components/layout/SEO';


const ServiziAggiuntivi = () => (
  <Layout>
    <Seo
      title={"Servizi Aggiuntivi | Autoscuola Faro a Pisa"}
      description={"Scopri i nostri servizi, come la visita medica per porto o detenzione d'arma da fuoco. Prenotiamo noi la commissione medica."}
      keywords={[
        "Visita medica porto d'arma Pisa",
        "Visita medica detenzione arma da fuoco Pisa"
      ]}
    />

    <ContentServices services={services} />
  </Layout>
);

export default ServiziAggiuntivi;
