import { faBook, faFileMedical, faGun } from '@fortawesome/free-solid-svg-icons';


export const services = {
  title: "Servizi aggiuntivi",
  subtitle: "",
  list: [
    {
      id: "s1",
      title: "Porto d’arma",
      text: "Presso l’autoscuola Faro è possibile effettuare la visita per il porto d’arma. Ecco cosa ti serve sapere:",
      icon: faBook,
      list: [
        {
          id: "arm1",
          text: "Porta con te il certificato anamnestico del medico curante"
        },
        {
          id: "arm2",
          text: "Il prezzo è di 90€ (comprensivo di visita e marca da bollo)"
        },
      ],
      price: "90 €",
    },
    {
      id: "s2",
      title: "Detenzione arma da fuoco",
      text: "Presso l’autoscuola Faro è possibile effettuare la visita per la detenzione d’arma da fuoco. Ecco cosa ti serve sapere:",
      icon: faGun,
      list: [
        {
          id: "foc1",
          text: "Porta con te il certificato anamnestico del medico curante"
        },
        {
          id: "foc2",
          text: "Il prezzo è di 90€ (comprensivo di visita e marca da bollo)"
        },
      ],
      price: "90 €",
    },
    {
      id: "s3",
      title: "Prenotazione commissione medica",
      text: "Lascia che il nostro staff provveda per te alla presentazione e prenotazione della visita per la CML. Per ulteriori informazioni, non esitare a contattarci.",
      list: null,
      icon: faFileMedical,
      list: [
        {
          id: "com1",
          text: "Il Prezzo della prenotazione della commissione medica è di 40€"
        },
      ]
    }
  ]
}