import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { services } from '../data/servizi-aggiuntivi/services';

export const ContentServices = (props) => {
  return (
    <div className="relative bg-gradient-to-b from-blue-accent-400 to-blue-accent-200">
      <div className="absolute inset-x-0 bottom-0">
        <svg
          viewBox="0 0 224 12"
          fill="currentColor"
          className="w-full -mb-1 text-white"
          preserveAspectRatio="none"
        >
          <path d="M0,0 C48.8902582,6.27314026 86.2235915,9.40971039 112,9.40971039 C137.776408,9.40971039 175.109742,6.27314026 224,0 L224,12.0441132 L0,12.0441132 L0,0 Z" />
        </svg>
      </div>


      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="mb-6 text-center font-roboto text-4xl font-semibold tracking-tight text-white sm:text-6xl leading-8">
            {props.services.title}
          </h2>
          <p className="text-base text-gray-700 md:text-lg">
            {props.services.subtitle}
          </p>
        </div>
        <div className="grid gap-8 row-gap-5 mb-8 md:row-gap-8 grid-cols-2">
          {props.services.list.map(s => (

            <div className={(s.id === "s3" ? "lg:px-56 px-2 col-span-2 " : "sm:col-span-1 col-span-2 ") + `p-5 duration-300 transform rounded hover:-translate-y-2`}>
              <div className={`mx-auto flex items-center bg-white justify-center w-20 h-20 mb-4 rounded-full bg-indigo-50`}>
                <FontAwesomeIcon icon={s.icon} className="h-10 w-10 text-blue-accent-700" />
              </div>
              <h6 className={`text-center mb-4 font-roboto font-semibold text-3xl leading-10 text-white`}>{s.title}</h6>
              <p className="text-center mb-3 font-sans font-normal text-xl text-white mx-auto">
                {s.text}
              </p>

              {s.list !== null ?
                <div className="flex">
                  <ul className="mx-auto">
                    {s.list.map(d => (
                      <li key={d.id} className="text-start font-sans font-extrabold text-xl text-white list-none">
                        {d.text}
                      </li>
                    ))}
                  </ul>
                </div> :
                null
              }
            </div>
          ))}



        </div>
        <div className="text-center">
          <a
            href="/contacts"
            className="inline-flex 
            items-center justify-center
             w-full h-12 px-6 font-roboto font-bold text-xl 
             tracking-tight text-blue-accent-700 
             transition duration-200 rounded 
             shadow-md md:w-auto 
             bg-white
             focus:shadow-outline focus:outline-none"
          >Contattaci
          </a>
        </div>
      </div>
    </div>
  );
};